<script setup lang="ts">
import { CardPostNew, ICardPostNewProps } from "@frontiers-vue3/public-pages";

interface ICardGridHomeHomeProps {
  cards: Array<ICardPostNewProps>;
  type?: "Primary" | "Secondary";
}

const props = withDefaults(defineProps<ICardGridHomeHomeProps>(), {
  cards: () => [],
  type: "Secondary"
});

const classList = computed((): Array<string> => {
  const classes = ["CardGridHome"];
  classes.push(`CardGridHome--${props.type.toLowerCase()}`);
  return classes;
});
</script>

<template>
  <div :class="classList">
    <CardPostNew v-for="(card, i) in cards" :key="i" v-bind="card" />
  </div>
</template>

<style lang="scss">
@mixin devicesCardHorizontal {
  &__link {
    @media #{$break-md} {
      flex-direction: row;
      display: grid;
      gap: var(--layout-gap);
      grid-template-columns: repeat(15, 1fr);
    }

    @media #{$break-sm} {
      grid-template-columns: repeat(14, 1fr);
    }

    @media #{$break-xs} {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }

  &__img {
    @media #{$break-md} {
      grid-column: 1/8;
      aspect-ratio: unset;
    }

    @media #{$break-sm} {
      grid-column: 1/7;
    }
  }

  &__body {
    @media #{$break-md} {
      grid-column: 8/16;
      margin-left: calc(0px - var(--layout-gap));
    }

    @media #{$break-sm} {
      grid-column: 7/15;
    }

    @media #{$break-xs} {
      margin-left: 0;
    }
  }
}
.CardGridHome {
  display: grid;
  gap: var(--layout-gap);
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;

  figcaption {
    display: none;
  }

  & > * {
    grid-area: span;
  }

  &--primary {
    grid-template-columns: repeat(3, 1fr);

    @media #{$break-xs} {
      grid-template-columns: repeat(1, 1fr);
    }

    & > *:first-child {
      grid-column: 1/4;

      @media #{$break-xs} {
        grid-area: span;
      }

      .Card {
        &__link {
          flex-direction: row;
          display: grid;
          gap: var(--layout-gap);
          grid-template-columns: repeat(15, 1fr);
        }

        &__img {
          grid-column: 1/9;
          aspect-ratio: unset;
        }

        &__body {
          grid-column: 9/16;
          margin-left: calc(0px - var(--layout-gap));
        }

        @include devicesCardHorizontal;
      }
    }

    @media #{$break-md} {
      display: flex;
      flex-direction: column;
    }

    .Card {
      @include devicesCardHorizontal;
    }
  }

  &--secondary {
    @media #{$break-md} {
      grid-template-columns: repeat(1, 1fr);
    }

    .Card {
      .Description {
        display: none;
      }

      @include devicesCardHorizontal;
    }
  }
}
</style>
