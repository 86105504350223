<script lang="ts" setup>
import { CustomLink, LinkColor } from "@frontiers-vue3/public-pages";
import { mapToAsidePosts, mapToCardPostNew } from "~/mappers/components";
import {
  IBriefPostResponse,
  ILandingPageResponse
} from "~/types/contentful/responses";
import { useContentfulQuery, useCacheService } from "~/services";
import type { ISeo } from "~/types/contentful/models";
import { homePageLDJson } from "~/json/google-structure-data";
import {
  CardNewsMainImageQueries,
  CardNewsSecondaryImageQueries
} from "~/const/ImageQueriesByMedia";

const { usePageCache, shortTTL } = useCacheService();
usePageCache(shortTTL);

const contenfulService = useContentfulQuery();
const config = useRuntimeConfig();
const store = useMain();

const landingPage: ILandingPageResponse = store.landingPage;

const getFeaturedPost = async () => {
  const post = await contenfulService.getBriefPostList({
    limit: 4,
    categories: ["Featured news"]
  });

  const cardsProps = post.items.map((card: IBriefPostResponse, i: number) => {
    let mappedCard;
    if (i === 0) {
      mappedCard = mapToCardPostNew(card, CardNewsMainImageQueries, false);
    } else {
      mappedCard = mapToCardPostNew(card, CardNewsSecondaryImageQueries, true);
    }
    return mappedCard;
  });
  return cardsProps;
};

const getLatestPosts = async () => {
  const posts = await contenfulService.getAsidePosts({ limit: 6 });
  return mapToAsidePosts(posts, false);
};

const featuredNews = await getFeaturedPost();

const latestPosts = await getLatestPosts();

const categoriesWithCards = computed(
  () => landingPage.featureCategoriesCollection.items
);

const seoData = computed((): ISeo => {
  return landingPage.seo;
});

const viewAllLink = {
  text: "View all news",
  url: "/news/all",
  target: "_self"
};

const seo = useSeo(seoData.value);
seo.setPageMetaInfo();

useHead({
  link: [
    {
      rel: "alternate",
      type: "application/rss+xml",
      title: "RSS Feed for Frontiers Science News",
      href: `${config.public.baseUrl}/news/rss`
    }
  ],
  script: [
    {
      type: "application/ld+json",
      children: JSON.stringify(homePageLDJson(config))
    }
  ]
});
</script>

<template>
  <HomeMainHeader />
  <div class="Layout Layout--paralax">
    <div class="Layout__body Layout__body--mainAside">
      <main class="Layout__main">
        <CardGridHome type="Primary" :cards="featuredNews" />
      </main>
      <div class="Layout__aside Layout__aside--home">
        <div class="Layout__aside__posts">
          <h2 class="Layout__aside__title">Latest posts</h2>
          <div class="Layout__aside__grid">
            <AsidePosts :posts="latestPosts" />
          </div>
          <CustomLink
            :link="viewAllLink"
            color="Maincolor"
            size="Small"
            icon="ChevronRight" />
        </div>
        <div class="Layout__aside__divider"></div>
        <div class="Layout__aside__categories">
          <h2 class="Layout__aside__title">Categories</h2>
          <CategoryCloud />
        </div>
      </div>
    </div>
    <div class="Layout__body">
      <div
        v-for="category in categoriesWithCards"
        class="Layout__section Layout__section--xl"
        :key="category.slug">
        <div class="Layout__section__header">
          <h2>{{ category.name }}</h2>
          <CustomLink
            :link="{
              text: 'View more',
              url: `/news/category/${category.slug}`
            }"
            color="Maincolor"
            size="Small"
            icon="ChevronRight" />
        </div>
        <CardGridHome type="Secondary" :cards="category.cards" />
      </div>
    </div>
    <div class="Layout__body">
      <CustomLink
        type="FlatButton"
        :link="viewAllLink"
        :color="LinkColor.MainColor" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.Layout {
  &__body {
    margin-top: var(--spacing-vertical-sm);

    & > .Link__wrapper {
      margin: var(--spacing-vertical-sm) auto 0 auto;
      text-align: center;
      display: block;

      @media #{$break-md} {
        margin-bottom: var(--spacing-vertical-xl);
      }
    }
  }

  &--paralax {
    background: #f7f7f7;
    z-index: 1;
    position: relative;
    padding-top: 1px;
  }
}
</style>
